/**
 * 현재날짜를 'yyyy-mm-dd' 로 표시
 * @returns {string}
 */
export function getDate() {
  const today = new Date();
  let mm = `${today.getMonth() + 1}`;
  let dd = `${today.getDate()}`;
  const yyyy = today.getFullYear();

  if (mm.length < 2) mm = `0${mm}`;
  if (dd.length < 2) dd = `0${dd}`;

  return `${yyyy}-${mm}-${dd}`;
}

/**
 * 01011112222 to 010-1111-2222
 * @param phone
 * @returns {string|string}
 */
export function getPhone(phone) {
  if (!phone) return '';
  let rtnStr = '';
  for (let i = 0; i < phone.length; i += 1) {
    if (i === 3 || i === 7) {
      rtnStr += '-';
    }
    rtnStr += phone.charAt(i);
  }
  return rtnStr;
}

/**
 * fitpet db에서 가져온 로그인타입을 변환하여 출력
 * @param type
 * @returns {string}
 */
export function getLoginType(type) {
  switch (type) {
    case 'A': return '일반회원';
    case 'N': return '네이버';
    case 'F': return '페이스북';
    case 'K': return '카카오';
    case 'G': return '구글';
    default: return '';
  }
}

/**
 * 쿠키에 저장하기
 * @param name
 * @param value
 */
export const setCookie = (name, value) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + 1);
  // 설정 일수만큼 현재시간에 만료값으로 지정

  const cvalue = escape(value) + ((1 === null) ? '' : `;    expires=${exdate.toUTCString()}`);
  document.cookie = `${name}=${cvalue}`;
};

/**
 * 쿠키 불러오기
 * @param name
 * @returns {string|null}
 */
export const getCookie = (name) => {
  let x;
  let y;
  const val = document.cookie.split(';');

  for (let i = 0; i < val.length; i += 1) {
    x = val[i].substr(0, val[i].indexOf('='));
    y = val[i].substr(val[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
    if (x === name) {
      return unescape(y); // unescape로 디코딩 후 값 리턴
    }
  } return true;
};
