// 여러군데에서 사용되는 style 모음
import { MAIN_COLOR, SUB_COLOR_1 } from './constants';

// 좌측 탭제외하고 오른쪽에 표시되는 페이지
// PageTitle, TabCont 포함, SideBar 미포함
export const pageStyle = {
  page: {
    backgroundColor: '#fafafa',
    width: 'calc(100vw - 200px)',
    height: '100vh',
    minHeight: '100vh',
    overflowY: 'auto',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'absolute',
    left: '200px',
  },
  working: {
    textAlign: 'center',
    marginTop: '220px',
    fontSize: '32px',
    fontWeight: 'bold',
    color: SUB_COLOR_1,
  },
};

// TableCell
export const tableStyle = {
  headRow: {
    height: '38px',
    backgroundColor: '#e0f2f1',
  },
  bodyRow: {
    height: '32px',
  },
  cell: {
    padding: '6px 8px !important',
    border: '1px solid rgba(224,224,224,1)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    position: 'relative',
  },
  headCell: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  tButton: {
    padding: '0 8px',
    minWidth: '44px',
    margin: '0 2px',
  },
  tIcon: {
    width: '32px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  row: {
    position: 'relative',
  },
  tPagination: {
    display: 'inline-block',
    width: '10px',
  },
  tLink: {
    color: SUB_COLOR_1,
    '&:visited' : {
      color: SUB_COLOR_1,
    }
  }
};

// Sign In, Sign Up
export const signInStyle = {
  paper: {
    width: '300px',
    margin: 'auto',
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 24px 24px',
  },
  avatar: {
    margin: 'auto',
    marginBottom: '8px',
    backgroundColor: MAIN_COLOR,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '16px',
  },
  submit: {
    marginTop: '24px',
    backgroundColor: MAIN_COLOR,
    '&:hover': {
      backgroundColor: '#26b9c9',
    },
  },
  signUp: {
    marginTop: '24px',
    color: '#aaa',
    margin: 'auto',
    display: 'block',
    border: '1px solid #aaa',
    padding: '4px 16px',
    width: '100px',
    textAlign: 'center',
  },
};

// Pie Chart
export const pieChart = {
  paper: {
    backgroundColor: '#ffffff',
    padding: '1rem',
    width: '100%',
    display: 'inline-block',
    boxSizing: 'border-box',
  },
  title: {
    margin: '1rem',
    marginBottom: '-2rem',
  },
};

export const CsvBtn = {
  csvBtn: {
    border: `2px solid ${MAIN_COLOR}`,
    color: MAIN_COLOR,
    padding: '12px 16px',
    position: 'absolute',
    borderRadius: '12px',
    bottom: 0,
    right: '1rem',
    display: 'inline-block',
    lineHeight: 'normal',
    fontWeight: 'bold',
    cursor: 'pointer',
    outline: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: MAIN_COLOR,
      color: '#fff',
    },
  },
  hidden: {
    display: 'none',
  },
};

export const Spinner = {
  dimmer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.5)',
    zIndex: '2',
  },
  progress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
  },
};
