import React, { Component } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SUB_COLOR_1} from '../common/constants';

const styles = () => ({
  tabs: {
    backgroundColor: SUB_COLOR_1,
    color: '#fff',
  },
  tabsIndicator: {
    backgroundColor: '#1F2041',
  },
  tab: {
    fontSize: '1rem',
    cursor: 'pointer',
  },
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  tab: PropTypes.number,
  handleTabClick: PropTypes.func.isRequired,
};

const defaultProps = {
  tab: 0,
};

class TabCont extends Component {
  shouldComponentUpdate(nextProps) {
    const { tab } = this.props;
    if (nextProps.tab === tab) return false;
    return true;
  }

  render() {
    const {
      tab, tabs, classes, handleTabClick,
    } = this.props;
    return (
      <AppBar className={classes.tabs} component="div" position="static" elevation={0}>
        <Tabs value={tab} classes={{ indicator: classes.tabsIndicator }}>
          {tabs.map(item => (
            <Tab
              key={item.pid}
              textColor="inherit"
              color="primary"
              label={item.text}
              onClick={() => handleTabClick(item.pid)}
              className={classes.tab}
            />
          ))}
        </Tabs>
      </AppBar>
    );
  }
}

TabCont.propTypes = propTypes;
TabCont.defaultProps = defaultProps;

export default withStyles(styles)(TabCont);
