import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import {
  DialogContent,
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { PetsModalTableHead } from '../common/constants';
import Fetch from '../common/fetches';
import { tableStyle } from '../common/styles';
import { Link } from 'react-router-dom';

const styles = () => ({
  ...tableStyle,
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
};

const defaultProps = {
  name: '',
};

class PetListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: [],
    };
  }

  componentDidMount() {
    this.getPets();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  async getPets() {
    const { id } = this.props;
    const url = `/members/${id}/pets`;
    const res = await Fetch('get', url);
    this.setState({
      isLoaded: true,
      data: res.data,
    });
  }

  render() {
    const { classes } = this.props;
    const { isLoaded, data } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
         
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow className={classes.headRow}>
                  {PetsModalTableHead.map(item => (
                    <TableCell align="center" key={item.key} className={classes.cell}>{item.value}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell className={classes.cell}>
                      <Link
                        to={{
                          pathname: "/pets",
                          state: { inputType: 'pet_id', searchInput: item.id }
                        }}
                        className={classes.tLink}
                      >
                        {item.id}
                      </Link>
                  </TableCell>
                    <TableCell className={classes.cell}>{item.name}</TableCell>
                    <TableCell className={classes.cell}>
                      {item.type === 'D'
                    && <img src="/icons/dog.png" alt="dog" className={classes.tIcon} />}
                      {item.type === 'C'
                    && <img src="/icons/cat.png" alt="cat" className={classes.tIcon} />}
                    </TableCell>
                    <TableCell className={classes.cell}>{item.species}</TableCell>
                    <TableCell className={classes.cell}>
                      {item.gender === 'F'
                    && <img src="/icons/female.png" alt="female" style={{ width: '16px' }} />}
                      {item.gender === 'M'
                    && <img src="/icons/male.png" alt="male" style={{ width: '16px' }} />}
                    </TableCell>
                    <TableCell className={classes.cell}>{`${item.weight} kg`}</TableCell>
                    <TableCell className={classes.cell}>
                      {item.birthday}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item.neutralization === 'Y'
                    && <img src="/icons/checkmark.png" style={{ width: '16px' }} alt="checked" />}
                    </TableCell>
                    <TableCell className={classes.cell}>{item.created_at}</TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={9}> 반려동물 정보가 없습니다.</TableCell></TableRow>}
              </TableBody>
            </Table>
          </DialogContent>
        </React.Fragment>
      );
    } return <div />;
  }
}

PetListModal.propTypes = propTypes;
PetListModal.defaultProps = defaultProps;

export default withStyles(styles)(PetListModal);
