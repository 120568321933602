import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/es/TextField/TextField';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    overflowX: 'hidden',
    padding: '0 2rem',
  },
  input: {
    margin: '12px',
    marginLeft: '0',
    width: '100%',
  },
  select: {
    margin: '12px',
    marginLeft: '0',
    width: '100%',
  },
  switch: {
    margin: '12px',
    marginLeft: '0',
  },
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  close: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

const defaultProps = {
};

class PetInspectionFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: '',
      type: '',
    };
  }

  componentDidMount() {
    if(this.props.inspectionData) {
      const { result } = this.props.inspectionData;
      this.setState({
        result: result,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  render() {
    const { classes, close, update, inspectionData } = this.props;
    const {
      result, type,
    } = this.state;
    return (
      <React.Fragment>
        <DialogTitle>
            {inspectionData ? '검사기록 수정' : '새 검사기록 등록'}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormControl className={classes.select}>
            <InputLabel htmlFor="type">검사지 종류</InputLabel>
            <Select
              native
              value={type}
              onChange={e => this.setState({ type: e.target.value })}
              inputProps={{ id: 'type' }}
              inputlabelprops={{
                shrink: true,
              }}
            >
              <option value="" />
              <option value="T">테코</option>
              <option value="C">청도</option>
            </Select>
          </FormControl>
          <br />
          <TextField
            className={classes.input}
            label="검사결과"
            value={result}
            onChange={e => this.setState({ result: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()} color="primary">취소</Button>
          <Button
            onClick={() => update({
              result, type,
            })}
            color="primary"
          >
            {inspectionData ? '수정' : '등록'}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

PetInspectionFormModal.propTypes = propTypes;
PetInspectionFormModal.defaultProps = defaultProps;

export default withStyles(styles)(PetInspectionFormModal);
