export const MAIN_COLOR = "#3ccbda";
export const SUB_COLOR_1 = "#219EAB";

export const MembersTableHead = [
  { key: 0, value: "등록번호" },
  { key: 1, value: "아이디" },
  { key: 2, value: "로그인" },
  { key: 3, value: "가입경로" },
  { key: 4, value: "APP 버전" },
  { key: 5, value: "전화번호" },
  { key: 6, value: "기기" },
  { key: 7, value: "OS 버전" },
  { key: 8, value: "모델명" },
  { key: 9, value: "국가" },
  { key: 10, value: "상태" },
  { key: 11, value: "가입일자" },
  { key: 12, value: "회원정보" },
  { key: 13, value: "펫정보" },
  { key: 14, value: "검사기록" },
  { key: 15, value: "계정통합" },
  { key: 16, value: "PW 초기화" },
];

export const InspectionTableHead = [
  { key: 0, value: '번호' },
  { key: 1, value: '반려동물이름' },
  { key: 2, value: '검사결과' },
  { key: 3, value: '정상' },
  { key: 4, value: '의심' },
  { key: 5, value: '검사일자' },
  { key: 6, value: '검사사진' },
  { key: 7, value: '앱 버전' },
  { key: 8, value: '알고리즘 버전' },
  { key: 9, value: '수정' },
  { key: 10, value: '삭제' },
];

export const PetsTableHead = [
  { key: 0, value: "등록번호" },
  { key: 1, value: "반려동물 이름" },
  { key: 2, value: "보호자 이름" },
  { key: 3, value: "구분" },
  { key: 4, value: "품종" },
  { key: 5, value: "성별" },
  { key: 6, value: "몸무게" },
  { key: 7, value: "생년월일" },
  { key: 8, value: "중성화" },
  { key: 9, value: "등록일" },
  { key: 10, value: "정보 수정" },
  { key: 11, value: "검사기록" },
  { key: 12, value: "보호자정보 조회" },
];

export const PetsModalTableHead = [
  { key: 0, value: "no" },
  { key: 1, value: "반려동물 이름" },
  { key: 2, value: "구분" },
  { key: 3, value: "품종" },
  { key: 4, value: "성별" },
  { key: 5, value: "몸무게" },
  { key: 6, value: "생년월일" },
  { key: 7, value: "중성화" },
  { key: 8, value: "등록일" },
];

export const AdminsTableHead = [
  { key: 0, value: "no" },
  { key: 1, value: "아이디" },
  { key: 2, value: "닉네임" },
  { key: 3, value: "상태" },
  { key: 4, value: "승인" },
  { key: 5, value: "삭제" },
  { key: 6, value: "차단" },
];

export const PushesTableHead = [
  { key: 0, value: "no" },
  { key: 1, value: "제목" },
  { key: 2, value: "내용" },
  { key: 3, value: "등록일" },
  { key: 4, value: "등록자" },
  { key: 5, value: "발송일" },
  { key: 6, value: "발송대상" },
  { key: 7, value: "상태" },
  { key: 8, value: "발송수" },
  { key: 9, value: "복사" },
  { key: 10, value: "수정" },
  { key: 11, value: "테스트발송" },
  { key: 12, value: "발송" },
  { key: 13, value: "삭제" },
];

export const PetSpecies = [
  {
    id: 1,
    type: "선택",
    key: "spe000",
    name: "선택하세요",
  },
  {
    id: 2,
    type: "강아지",
    key: "spe001",
    name: "골든 리트리버",
  },
  {
    id: 3,
    type: "강아지",
    key: "spe002",
    name: "그레이 하운드",
  },
  {
    id: 4,
    type: "강아지",
    key: "spe003",
    name: "그레이트 데인",
  },
  {
    id: 5,
    type: "강아지",
    key: "spe004",
    name: "그레이트 피레니즈",
  },
  {
    id: 6,
    type: "강아지",
    key: "spe005",
    name: "닥스훈트",
  },
  {
    id: 7,
    type: "강아지",
    key: "spe006",
    name: "달마시안",
  },
  {
    id: 8,
    type: "강아지",
    key: "spe007",
    name: "도베르만",
  },
  {
    id: 9,
    type: "강아지",
    key: "spe008",
    name: "도사",
  },
  {
    id: 10,
    type: "강아지",
    key: "spe009",
    name: "동경견",
  },
  {
    id: 11,
    type: "강아지",
    key: "spe010",
    name: "라사 압소",
  },
  {
    id: 12,
    type: "강아지",
    key: "spe011",
    name: "라이카",
  },
  {
    id: 13,
    type: "강아지",
    key: "spe012",
    name: "래브라도 리트리버",
  },
  {
    id: 14,
    type: "강아지",
    key: "spe013",
    name: "롯트와일러",
  },
  {
    id: 15,
    type: "강아지",
    key: "spe014",
    name: "말티즈",
  },
  {
    id: 16,
    type: "강아지",
    key: "spe015",
    name: "미니어쳐 핀셔",
  },
  {
    id: 17,
    type: "강아지",
    key: "spe016",
    name: "믹스견",
  },
  {
    id: 18,
    type: "강아지",
    key: "spe017",
    name: "바셋 하운드",
  },
  {
    id: 19,
    type: "강아지",
    key: "spe018",
    name: "버니즈 마운틴 독",
  },
  {
    id: 20,
    type: "강아지",
    key: "spe019",
    name: "베들링턴 테리어",
  },
  {
    id: 21,
    type: "강아지",
    key: "spe020",
    name: "벨지언 셰퍼드 독",
  },
  {
    id: 22,
    type: "강아지",
    key: "spe021",
    name: "보더 콜리",
  },
  {
    id: 23,
    type: "강아지",
    key: "spe022",
    name: "보르조이",
  },
  {
    id: 24,
    type: "강아지",
    key: "spe023",
    name: "보스턴 테리어",
  },
  {
    id: 25,
    type: "강아지",
    key: "spe024",
    name: "복서",
  },
  {
    id: 26,
    type: "강아지",
    key: "spe025",
    name: "불 테리어",
  },
  {
    id: 27,
    type: "강아지",
    key: "spe026",
    name: "불독",
  },
  {
    id: 28,
    type: "강아지",
    key: "spe027",
    name: "브리타니",
  },
  {
    id: 29,
    type: "강아지",
    key: "spe028",
    name: "비글",
  },
  {
    id: 30,
    type: "강아지",
    key: "spe029",
    name: "비숑 프리제",
  },
  {
    id: 31,
    type: "강아지",
    key: "spe030",
    name: "사모예드",
  },
  {
    id: 32,
    type: "강아지",
    key: "spe031",
    name: "삽살개",
  },
  {
    id: 33,
    type: "강아지",
    key: "spe032",
    name: "샤 페이",
  },
  {
    id: 34,
    type: "강아지",
    key: "spe033",
    name: "세인트 버나드",
  },
  {
    id: 35,
    type: "강아지",
    key: "spe034",
    name: "셔틀랜드 쉽독",
  },
  {
    id: 36,
    type: "강아지",
    key: "spe035",
    name: "슈나우저",
  },
  {
    id: 37,
    type: "강아지",
    key: "spe036",
    name: "스코티시 테리어",
  },
  {
    id: 38,
    type: "강아지",
    key: "spe037",
    name: "시바",
  },
  {
    id: 39,
    type: "강아지",
    key: "spe038",
    name: "시베리안 허스키",
  },
  {
    id: 40,
    type: "강아지",
    key: "spe039",
    name: "시츄",
  },
  {
    id: 41,
    type: "강아지",
    key: "spe040",
    name: "아메리칸 불리",
  },
  {
    id: 42,
    type: "강아지",
    key: "spe041",
    name: "아메리칸 코커 스패니얼",
  },
  {
    id: 43,
    type: "강아지",
    key: "spe042",
    name: "아메리칸 핏 불 테리어",
  },
  {
    id: 44,
    type: "강아지",
    key: "spe043",
    name: "아키타",
  },
  {
    id: 45,
    type: "강아지",
    key: "spe044",
    name: "아프간 하운드",
  },
  {
    id: 46,
    type: "강아지",
    key: "spe045",
    name: "알래스칸 맬러뮤트",
  },
  {
    id: 47,
    type: "강아지",
    key: "spe046",
    name: "오스트레일리안 실키 테리어",
  },
  {
    id: 48,
    type: "강아지",
    key: "spe047",
    name: "올드 잉글리시 쉽독",
  },
  {
    id: 49,
    type: "강아지",
    key: "spe048",
    name: "요크셔 테리어",
  },
  {
    id: 50,
    type: "강아지",
    key: "spe049",
    name: "웨스트 하이랜드 화이트테리어",
  },
  {
    id: 51,
    type: "강아지",
    key: "spe050",
    name: "웰시 코기",
  },
  {
    id: 52,
    type: "강아지",
    key: "spe051",
    name: "잉글리시 세터",
  },
  {
    id: 53,
    type: "강아지",
    key: "spe052",
    name: "잉글리시 코커 스패니얼",
  },
  {
    id: 54,
    type: "강아지",
    key: "spe053",
    name: "잭 러셀 테리어",
  },
  {
    id: 55,
    type: "강아지",
    key: "spe054",
    name: "저먼 셰퍼드 독",
  },
  {
    id: 56,
    type: "강아지",
    key: "spe055",
    name: "제페니스 스피츠",
  },
  {
    id: 57,
    type: "강아지",
    key: "spe056",
    name: "제페니스 친",
  },
  {
    id: 58,
    type: "강아지",
    key: "spe057",
    name: "진돗개",
  },
  {
    id: 59,
    type: "강아지",
    key: "spe058",
    name: "차우차우",
  },
  {
    id: 60,
    type: "강아지",
    key: "spe059",
    name: "치와와",
  },
  {
    id: 61,
    type: "강아지",
    key: "spe060",
    name: "카네 코르소",
  },
  {
    id: 62,
    type: "강아지",
    key: "spe061",
    name: "캐벌리어 킹 찰스 스페니얼",
  },
  {
    id: 63,
    type: "강아지",
    key: "spe062",
    name: "케리 블루 테리어",
  },
  {
    id: 64,
    type: "강아지",
    key: "spe063",
    name: "코커 스패니얼",
  },
  {
    id: 65,
    type: "강아지",
    key: "spe064",
    name: "코튼 드 툴리어",
  },
  {
    id: 66,
    type: "강아지",
    key: "spe065",
    name: "콜리",
  },
  {
    id: 67,
    type: "강아지",
    key: "spe066",
    name: "티베탄 스패니얼",
  },
  {
    id: 68,
    type: "강아지",
    key: "spe067",
    name: "파피용",
  },
  {
    id: 69,
    type: "강아지",
    key: "spe068",
    name: "퍼그",
  },
  {
    id: 70,
    type: "강아지",
    key: "spe069",
    name: "페키니즈",
  },
  {
    id: 71,
    type: "강아지",
    key: "spe070",
    name: "페터데일 테리어",
  },
  {
    id: 72,
    type: "강아지",
    key: "spe071",
    name: "포메라니안",
  },
  {
    id: 73,
    type: "강아지",
    key: "spe072",
    name: "포인터(포인팅 독 )",
  },
  {
    id: 74,
    type: "강아지",
    key: "spe073",
    name: "폭스테리어",
  },
  {
    id: 75,
    type: "강아지",
    key: "spe074",
    name: "푸들",
  },
  {
    id: 76,
    type: "강아지",
    key: "spe075",
    name: "풀리",
  },
  {
    id: 77,
    type: "강아지",
    key: "spe076",
    name: "풍산개",
  },
  {
    id: 78,
    type: "강아지",
    key: "spe077",
    name: "프렌치 불독",
  },
  {
    id: 79,
    type: "강아지",
    key: "spe078",
    name: "기타 종류",
  },
  {
    id: 80,
    type: "고양이",
    key: "spe101",
    name: "나폴레옹",
  },
  {
    id: 81,
    type: "고양이",
    key: "spe102",
    name: "나폴레옹 롱헤어",
  },
  {
    id: 82,
    type: "고양이",
    key: "spe103",
    name: "네벨룽",
  },
  {
    id: 83,
    type: "고양이",
    key: "spe104",
    name: "노르웨이지안 포레스트 캣",
  },
  {
    id: 84,
    type: "고양이",
    key: "spe105",
    name: "데본 렉스",
  },
  {
    id: 85,
    type: "고양이",
    key: "spe106",
    name: "돈스코이",
  },
  {
    id: 86,
    type: "고양이",
    key: "spe107",
    name: "라펌",
  },
  {
    id: 87,
    type: "고양이",
    key: "spe108",
    name: "라펌 쇼트헤어",
  },
  {
    id: 88,
    type: "고양이",
    key: "spe109",
    name: "래그돌",
  },
  {
    id: 89,
    type: "고양이",
    key: "spe110",
    name: "러시안 블루",
  },
  {
    id: 90,
    type: "고양이",
    key: "spe111",
    name: "망스",
  },
  {
    id: 91,
    type: "고양이",
    key: "spe112",
    name: "먼치킨",
  },
  {
    id: 92,
    type: "고양이",
    key: "spe113",
    name: "먼치킨 롱헤어",
  },
  {
    id: 93,
    type: "고양이",
    key: "spe114",
    name: "메인 쿤",
  },
  {
    id: 94,
    type: "고양이",
    key: "spe115",
    name: "믹스묘",
  },
  {
    id: 95,
    type: "고양이",
    key: "spe116",
    name: "민스킨",
  },
  {
    id: 96,
    type: "고양이",
    key: "spe117",
    name: "발리니즈",
  },
  {
    id: 97,
    type: "고양이",
    key: "spe118",
    name: "뱅갈",
  },
  {
    id: 98,
    type: "고양이",
    key: "spe119",
    name: "버만",
  },
  {
    id: 99,
    type: "고양이",
    key: "spe120",
    name: "버미즈",
  },
  {
    id: 100,
    type: "고양이",
    key: "spe121",
    name: "버밀라",
  },
  {
    id: 101,
    type: "고양이",
    key: "spe122",
    name: "봄베이",
  },
  {
    id: 102,
    type: "고양이",
    key: "spe123",
    name: "브라질리안 숏헤어",
  },
  {
    id: 103,
    type: "고양이",
    key: "spe124",
    name: "브리티시 롱헤어",
  },
  {
    id: 104,
    type: "고양이",
    key: "spe125",
    name: "브리티시 쇼트헤어",
  },
  {
    id: 105,
    type: "고양이",
    key: "spe126",
    name: "사바나",
  },
  {
    id: 106,
    type: "고양이",
    key: "spe127",
    name: "샤트룩스",
  },
  {
    id: 107,
    type: "고양이",
    key: "spe128",
    name: "샴",
  },
  {
    id: 108,
    type: "고양이",
    key: "spe129",
    name: "세렝게티",
  },
  {
    id: 109,
    type: "고양이",
    key: "spe130",
    name: "셀커크 렉스",
  },
  {
    id: 110,
    type: "고양이",
    key: "spe131",
    name: "소말리",
  },
  {
    id: 111,
    type: "고양이",
    key: "spe132",
    name: "스노우슈",
  },
  {
    id: 112,
    type: "고양이",
    key: "spe133",
    name: "스코티시 폴드",
  },
  {
    id: 113,
    type: "고양이",
    key: "spe134",
    name: "스코티시 폴드 롱헤어",
  },
  {
    id: 114,
    type: "고양이",
    key: "spe135",
    name: "스키프 토이 밥테일",
  },
  {
    id: 115,
    type: "고양이",
    key: "spe136",
    name: "스털링",
  },
  {
    id: 116,
    type: "고양이",
    key: "spe137",
    name: "스핑크스",
  },
  {
    id: 117,
    type: "고양이",
    key: "spe138",
    name: "시베리안",
  },
  {
    id: 118,
    type: "고양이",
    key: "spe139",
    name: "싱가푸라",
  },
  {
    id: 119,
    type: "고양이",
    key: "spe140",
    name: "아메리칸 밥테일",
  },
  {
    id: 120,
    type: "고양이",
    key: "spe141",
    name: "아메리칸 밥테일 쇼트헤어",
  },
  {
    id: 121,
    type: "고양이",
    key: "spe142",
    name: "아메리칸 쇼트헤어",
  },
  {
    id: 122,
    type: "고양이",
    key: "spe143",
    name: "아메리칸 컬",
  },
  {
    id: 123,
    type: "고양이",
    key: "spe144",
    name: "아메리칸 컬 롱헤어",
  },
  {
    id: 124,
    type: "고양이",
    key: "spe145",
    name: "아비시니안",
  },
  {
    id: 125,
    type: "고양이",
    key: "spe146",
    name: "아시안 세미롱헤어",
  },
  {
    id: 126,
    type: "고양이",
    key: "spe147",
    name: "아시안 쇼트헤어",
  },
  {
    id: 127,
    type: "고양이",
    key: "spe148",
    name: "오리엔탈 롱헤어",
  },
  {
    id: 128,
    type: "고양이",
    key: "spe149",
    name: "오리엔탈 쇼트헤어",
  },
  {
    id: 129,
    type: "고양이",
    key: "spe150",
    name: "오스트레일리안 미스트",
  },
  {
    id: 130,
    type: "고양이",
    key: "spe151",
    name: "옥시캣",
  },
  {
    id: 131,
    type: "고양이",
    key: "spe152",
    name: "요크 초콜렛",
  },
  {
    id: 132,
    type: "고양이",
    key: "spe153",
    name: "유러피안 쇼트헤어",
  },
  {
    id: 133,
    type: "고양이",
    key: "spe154",
    name: "이그저틱 쇼트헤어",
  },
  {
    id: 134,
    type: "고양이",
    key: "spe155",
    name: "이집션 마우",
  },
  {
    id: 135,
    type: "고양이",
    key: "spe156",
    name: "자바니즈",
  },
  {
    id: 136,
    type: "고양이",
    key: "spe157",
    name: "저먼 렉스",
  },
  {
    id: 137,
    type: "고양이",
    key: "spe158",
    name: "제페니즈 밥테일",
  },
  {
    id: 138,
    type: "고양이",
    key: "spe159",
    name: "제페니즈 밥테일 롱헤어",
  },
  {
    id: 139,
    type: "고양이",
    key: "spe160",
    name: "친칠라",
  },
  {
    id: 140,
    type: "고양이",
    key: "spe161",
    name: "카호마니",
  },
  {
    id: 141,
    type: "고양이",
    key: "spe162",
    name: "캘리포니아 스팽글드 캣",
  },
  {
    id: 142,
    type: "고양이",
    key: "spe163",
    name: "컬러포인트 쇼트헤어",
  },
  {
    id: 143,
    type: "고양이",
    key: "spe164",
    name: "코니시 렉스",
  },
  {
    id: 144,
    type: "고양이",
    key: "spe165",
    name: "코렛",
  },
  {
    id: 145,
    type: "고양이",
    key: "spe166",
    name: "코리안 롱 헤어",
  },
  {
    id: 146,
    type: "고양이",
    key: "spe167",
    name: "코리안 쇼트헤어",
  },
  {
    id: 147,
    type: "고양이",
    key: "spe168",
    name: "쿠릴리안 밥테일 롱헤어",
  },
  {
    id: 148,
    type: "고양이",
    key: "spe169",
    name: "쿠릴리안 밥테일 쇼트헤어",
  },
  {
    id: 149,
    type: "고양이",
    key: "spe170",
    name: "킴릭",
  },
  {
    id: 150,
    type: "고양이",
    key: "spe171",
    name: "타이",
  },
  {
    id: 151,
    type: "고양이",
    key: "spe172",
    name: "터키쉬 앙고라",
  },
  {
    id: 152,
    type: "고양이",
    key: "spe173",
    name: "터키시 밴",
  },
  {
    id: 153,
    type: "고양이",
    key: "spe174",
    name: "토이거",
  },
  {
    id: 154,
    type: "고양이",
    key: "spe175",
    name: "통키니즈",
  },
  {
    id: 155,
    type: "고양이",
    key: "spe176",
    name: "페르시안",
  },
  {
    id: 156,
    type: "고양이",
    key: "spe177",
    name: "페르시안 친칠라",
  },
  {
    id: 157,
    type: "고양이",
    key: "spe178",
    name: "피터볼드",
  },
  {
    id: 158,
    type: "고양이",
    key: "spe179",
    name: "픽시밥",
  },
  {
    id: 159,
    type: "고양이",
    key: "spe180",
    name: "픽시밥 롱헤어",
  },
  {
    id: 160,
    type: "고양이",
    key: "spe181",
    name: "하바나 브라운",
  },
  {
    id: 161,
    type: "고양이",
    key: "spe182",
    name: "하이랜더 롱헤어",
  },
  {
    id: 162,
    type: "고양이",
    key: "spe183",
    name: "하이랜더 쇼트헤어",
  },
  {
    id: 163,
    type: "고양이",
    key: "spe184",
    name: "히말라얀",
  },
  {
    id: 164,
    type: "고양이",
    key: "spe185",
    name: "기타묘종",
  },
];

// 통합 데이터 추가

export const API_DATA = {
  data: [
    {
      id: 63940,
      login_id: "test001@fitpet.co.kr",
      login_type: "A",
      pet_count: "1",
      first_pet_name: "재롱이",
      register_path: "S",
      last_logined_at: "2019-11-07 16:59:37",
    },
    {
      id: 67628,
      login_id: "237489723498237498",
      login_type: "K",
      pet_count: "0",
      first_pet_name: "",
      register_path: "A",
      last_logined_at: "2019-11-07 16:59:37",
    },
    {
      id: 79061,
      login_id: "7981234798213874991234",
      login_type: "N",
      pet_count: "3",
      first_pet_name: "멍멍이",
      register_path: "S",
      last_logined_at: "2019-11-07 16:59:37",
    },
  ],
};

export const LIST_DATA = {
  account_integrated_at: "2020-07-14 20:17:05",
  ad_id: null,
  address: null,
  app_version: null,
  birthday: "1970-01-01",
  created_at: "2020-07-14 20:17:05",
  deviceId: null,
  deviceType: null,
  device_model: null,
  email: "james@fitpet.co.kr",
  fcm_token: null,
  gender: null,
  geoLocation: "KR",
  hash_id: "da39a3ee5e6b4b0d3255bfef95601890afd80709",
  id: 82344,
  identifier: null,
  integrate_member_id: 82344,
  is_account_integrated: "Y",
  is_phone_certificated: "Y",
  is_tester: "N",
  lastInspected_at: null,
  lastLogined_at: "2020-07-14 20:17:05",
  locCheck_agree: "N",
  locX: "-1.00",
  locY: "-1.00",
  loginId: "james@fitpet.co.kr",
  loginType: "A",
  login_type_legacy: null,
  name: null,
  noti_agree: "Y",
  oldMember: "N",
  os_version: null,
  phone: "01062014306",
  phone_certificated_at: "2020-07-14 20:17:05",
  status: "Y",
  sub_path: "A",
  temp_token: "3SzUjiZrMrn69EmrRgtYCoOdRHTupCRt4q2VEHlD",
  updated_at: "2020-07-14 20:17:05",
};
