import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import {
  Button, DialogContent, Link, Dialog,
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { tableStyle } from '../common/styles';
import Fetch from '../common/fetches';
import { InspectionTableHead } from '../common/constants';
import PetInspectionFormModal from "./PetInspectionFormModal";
import Draggable from 'react-draggable';


const styles = () => ({
  ...tableStyle,
  algorithmCell: {
    ...tableStyle.cell,
    textAlign: 'left',
    '&>:first-child': {
      marginBottom: '3px',
    }
  },
  targetInspectionRow: {
    background: '#fffd9a',
  },
  modalBackground: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height:'100vh',
    background: 'transparent',
  },
  imgPopUp: {
    position: 'fixed',
    top: '5vh',
    right: '5vh',
    height: '90vh',
  },
  imgHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    background: 'white',
    padding: '10px',
    position: 'relative',
    height: '40px',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  inspectionImg: {
    width: 'auto',
    height: 'calc(100% - 40px)',
  },
  showImageLink: {
    padding: '5px 10px',
    border: '1px solid rgba(63, 81, 181, 0.5)',
    borderRadius: '3px',
    fontWeight: 'bold',
    '&:visited' : {
      color: '#660099',
      border: '1px solid #913abd',
    }
  }
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
};

const defaultProps = {
  name: '',
};

class InspectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      targetInspectionData: {},
      isLoaded: false,
      isImgUrlModalOpen: false,
      isOneInspectionModalOpen: false,
      imgUrl: '',
      targetInspectionId: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  /**
   * 특정 회원 혹은 반려동물의 검사기록 모두 불러오기
   * @returns {Promise<void>}
   */
  async getData() {
    const { url } = this.props;
    const res = await Fetch('get', url);
    this.setState({
      data: res.data,
      isLoaded: true,
    });
  }

  async getImg(petId, id) {
    const url = `/pets/${petId}/inspections/${id}`;
    const res = await Fetch('get', url);
    if (res.imgUrl) {
      this.setState({
        isImgUrlModalOpen: true,
        imgUrl: res.imgUrl,
        targetInspectionId: id,
      });
    }
  }

  handleConfirmDeleteInspection(id) {
    const response = window.confirm('검사기록을 삭제하시겠습니까?');
    if (response) {
      this.deleteInspection(id);
    }
  }

  async deleteInspection(id) {
    const url = `/pets/${id}/inspections`;
    const res = await Fetch('delete', url);
    console.log('res', res);
    if (res.msg) {
      alert(res.msg);
      this.getData();
    }
  }

  async updateInspection(newData, id) {
    if(!newData.type || !newData.result) {
      alert('검사지종류와 결과값을 모두 입력해주세요.');
      return;
    }

    const resultNumber = newData.result.split(' ').join('');
    const notIntRegex = /[^0-9]/gi;
    if (notIntRegex.test(resultNumber)) {
      alert('결과값은 숫자로만 입력해주세요.');
      return;
    }

    const url=`/inspections/${id}/result`;
    const bodyData = {
      result: newData.result,
      aheadType: newData.type,
    }
    const res = await Fetch('post', url, bodyData);
    console.log('res', res);
    if(res.msg) {
      alert(res.msg);
      this.getData();
      this.setState({
        isOneInspectionModalOpen : false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      data, isLoaded, isImgUrlModalOpen, imgUrl, isOneInspectionModalOpen, targetInspectionData, targetInspectionId
    } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow className={classes.headRow}>
                  {InspectionTableHead.map(item => (
                    <TableCell align="center" key={item.key} className={classes.cell}>{item.value}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? data.map(item => (
                  <TableRow key={item.id} className={item.id === targetInspectionId ? classes.targetInspectionRow : ''}>
                    <TableCell className={classes.cell}>{item.id}</TableCell>
                    <TableCell className={classes.cell}>
                      {item.pets_name || item.pet_name}
                    </TableCell>
                    <TableCell className={classes.cell}>{item.result}</TableCell>
                    <TableCell className={classes.cell}>{item.nomality}</TableCell>
                    <TableCell className={classes.cell}>{item.doubt}</TableCell>
                    <TableCell className={classes.cell}>{item.inspected_at}</TableCell>
                    <TableCell className={classes.cell}>
                      <Link
                        href={`#${item.id}`}
                        className={classes.showImageLink}
                        onClick={() => this.getImg(item.pet_id, item.id)}
                      >
                        보기
                      </Link>
                    </TableCell>
                    <TableCell className={classes.cell}>{item.app_version ? item.app_version : '-' }</TableCell>
                    <TableCell className={classes.algorithmCell}>
                      <p>{`Client : ${item.algorithm_sd_version ? item.algorithm_sd_version : '-' }`}</p>
                      <p>{`Server : ${item.algorithm_rf_version ? item.algorithm_rf_version : '-' }`}</p>
                    </TableCell>

                    <TableCell className={classes.cell}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() => this.setState({isOneInspectionModalOpen: true, targetInspectionData: item })}
                      >
                        수정
                      </Button>
                    </TableCell>

                    <TableCell className={classes.cell}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() => this.handleConfirmDeleteInspection(item.id)}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                )) : <TableRow><TableCell colSpan={11}> 검사기록이 없습니다.</TableCell></TableRow>}
              </TableBody>
            </Table>
          </DialogContent>

          <Dialog
            open={isOneInspectionModalOpen}
            onClose={() => this.setState({ isOneInspectionModalOpen: false })}
            maxWidth='sm'
            fullWidth
          >
            <PetInspectionFormModal
              inspectionData={targetInspectionData}
              close={() => this.setState({ isOneInspectionModalOpen: false })}
              update={newData => this.updateInspection(newData, targetInspectionData.id)}
            />
          </Dialog>

          {isImgUrlModalOpen && (
            <>
              <div 
                className={classes.modalBackground} 
                onClick={() => this.setState({ isImgUrlModalOpen: false, targetInspectionId: null })}
              />
              <Draggable>
                <div className={classes.imgPopUp} style={{ zIndex: 100 }}>
                  <div className={classes.imgHeader}>
                    <h2>검사번호 : {targetInspectionId}</h2>
                  </div>
                  <img className={classes.inspectionImg} draggable="false" src={imgUrl} alt="검사 결과 사진" />
                  {/* <img className={classes.inspectionImg} draggable="false" src="https://fitpet-app.s3.ap-northeast-2.amazonaws.com/images/inspections/2563/06/163128.jpg" alt="검사 결과 사진" /> */}
                </div>
              </Draggable>
            </>
            )
          }

        </React.Fragment>
      );
    }
    return <div />;
  }
}

InspectionModal.propTypes = propTypes;
InspectionModal.defaultProps = defaultProps;

export default withStyles(styles)(InspectionModal);
