import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import {
  DialogContent, DialogTitle, Divider, Typography,
} from '@material-ui/core';
import Fetch from '../common/fetches';
import { getPhone } from '../common/utils';

const styles = () => ({
  content: {
    marginTop: '1rem',
  },
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

class MemberInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.getMember();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  async getMember() {
    const { id } = this.props;
    const url = `/pets/${id}/member`;
    const res = await Fetch('get', url);
    if (res.data) {
      this.setState({
        data: res.data,
        isLoaded: true,
      });
    }
  }

  render() {
    const { classes, onClose } = this.props;
    const { data, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          <DialogTitle onClose={() => onClose()}>보호자 정보</DialogTitle>
          <Divider />
          <DialogContent className={classes.content}>
            <Typography>
              {`이름 : ${data.name}`}
            </Typography>
            <Typography>
              {`이메일 : ${data.email}`}
            </Typography>
            <Typography>
              {`생년월일 : ${data.birthday}`}
            </Typography>
            <Typography>
              {`핸드폰번호 : ${getPhone(data.phone)}`}
            </Typography>
          </DialogContent>
        </React.Fragment>
      );
    } return <div />;
  }
}

MemberInfoModal.propTypes = propTypes;
MemberInfoModal.defaultProps = defaultProps;

export default withStyles(styles)(MemberInfoModal);
