import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  FormControl,
  Icon, Input,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { signInStyle } from '../../common/styles';
import Fetch from '../../common/fetches';
import { setCookie } from '../../common/utils';
import { withRouter, Link } from 'react-router-dom';


const styles = theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default,
    display: 'block', // Fix IE 11 issue.
    position: 'absolute',
    top: 0,
  },
  ...signInStyle,
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      pw: '',
    };
  }

  shouldComponentUpdate() {
    return false;
  }

  async login() {
    const { id, pw } = this.state;
    const url = '/auth/login';
    const body = {
      loginId: id,
      password: pw,
    };
    const res = await Fetch('post', url, body);
    if (res.token) {
      setCookie('loginData', JSON.stringify(res));
      this.props.history.push('/');
    } else if (res.error) {
      alert(res.error.msg);
    }
  }

  handlekeyPress(e) {
    if (e.keyCode === 13) {
      this.login();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.root}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Icon>lock</Icon>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="id">ID</InputLabel>
              <Input
                id="id"
                name="id"
                autoFocus
                onChange={e => this.setState({ id: e.target.value })}
                onKeyDown={e => this.handlekeyPress(e)}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => this.setState({ pw: e.target.value })}
                onKeyDown={e => this.handlekeyPress(e)}
              />
            </FormControl>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => this.login()}
            >
              Sign in
            </Button>
          </form>
        </Paper>
        <Link to="/sign_up"><Button className={classes.signUp}>Sign Up</Button></Link>
      </main>
    );
  }
}

SignIn.propTypes = propTypes;


export default withRouter(withStyles(styles)(SignIn));
