import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DialogTitle, DialogContent } from '@material-ui/core';
import Fetch from '../common/fetches';

const styles = () => ({
  list: {
    width: "420px",
    marginBottom: "75px"
  },
  listItem: {
    marginBottom: "18px",
  },
  listContainer: {
    display: "flex",
    alignItems: "center",
    height: "100px",
  },
  listContent: { 
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "90px",
    padding: "0 16px",
    fontSize: "16px",
    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.2)",
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "4px",
    marginLeft: "16px"
  },
  listContentHead: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    color: "#31BEC8",
    opacity: "0.9"
  },
  listContentHeadAccount: {
    color: "#434343",
    fontSize: "20px",
    opacity: "1",
    marginBottom: "8px"
  },
  listContentBody: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    color: "rgba(0,0,0,0.6)"
  },
  radio: {
    display: 'none'
  },
  completeBtn: {
    width: "100%",
    height: "48px",
    fontSize: "18px",
    backgroundColor: "#3ccbda",
    color: "white",
    border: "none",
    borderRadius: "5px"
  },
  dialogContent: {
    fontSize: "15px",
    marginBottom: "20px"

  }
});

const ListContent = withStyles(styles)(({ info, classes, handleChecked }) => {
  const logInType = (data) => {
    switch (data.login_type) {
      case "A":
        return data.login_id;
      case "K":
        return "카카오톡";
      case "N":
        return "네이버";
      default:
        return data.login_id;
    }
  };

  const petType = (data) => {
    if (!data.first_pet_name) {
      return "등록해주세요";
    } else if (parseInt(data.pet_count) === 1) {
      return data.first_pet_name;
    } else {
      return data.first_pet_name + " 외 " + (parseInt(data.pet_count) - 1) + "마리"
    }
  };

  info.selectedAccount = logInType(info);

  return (
    <li className={classes.listItem}>
      <section>
        <label htmlFor={`${info.id}`} className={classes.listContainer}>
          <input id={`${info.id}`} type="radio" name="account" onChange={() => { handleChecked(info) }} />
          <div className={classes.listContent}>
            <div className={classes.listContentHead}>
              <h1 className={classes.listContentHeadAccount}>{logInType(info)}</h1>
              <h2>{petType(info)}</h2>
            </div>
            <div className={classes.listContentBody}>
              <p>{info.last_logined_at}</p>
            </div>
          </div>
        </label>
      </section>
    </li>
  )
})



class IntegrateModal extends Component {
  constructor(props) {
    super(props);
    // data: 해당 회원이 가진 아이디 목록
    // isLoaded: 아이디 목록 데이터를 받아왔는지 여부
    // selectedId: 대표 아이디로 선택한 아이디의 id(데이터베이스상의 id번호)
    // selectedLoginId: 대표 아이디로 선택한 아이디(이메일 등)
    this.state = {
      data: [],
      isLoaded: false,
      selectedId: '',
      selectedLoginId: '',
    };
  }

  componentDidMount() {
    this.getMemberIds();
  }

  // 3. 선택한 아이디로 아이디 통합하기
  async postSelectedId() {
    try {
      const postIdUrl = '/integration';
      const body = {
        integration_id: this.state.selectedId,
      };
      const response = await Fetch('post', postIdUrl, body);

      const integratedMember = response.filter(user => user.is_account_integrated === 'Y');
      if (integratedMember.length === 1 && integratedMember[0].id === this.state.selectedId) {
        alert('계정 통합에 성공했습니다.');
        this.props.searchMember();
        this.props.closeModal();
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 2. 토큰으로 회원 아이디 배열을 받아 state.data에 넣기
  async getMemberIds() {
    if (!this.props.phone) {
      alert('휴대폰 정보가 없습니다.');
      this.props.closeModal();
      return;
    }

    try {
      const url = `/account-list?phone=${this.props.phone}`;
      const response = await Fetch('get', url);      
      // console.log('클릭한 회원의 아이디 리스트들', response);
      if (response) {
        const isIntegrated = response.find(idInfo => idInfo.is_account_integrated === 'Y');
        if (isIntegrated) {
          alert('현재 계정의 휴대폰 번호로 이미 계정 통합을 완료했습니다.');
          this.props.closeModal();
          return;
        }
        this.setState({
          data: response,
          isLoaded: true,
        });
      }
    } catch (error) {
      this.props.closeModal();
    }
  }

  onHover = (event) => {
    event.target.style.cursor = "pointer";
    event.target.style.opacity = "0.5"
  };

  onLeave = (event) => {
    event.target.style.opacity = "1"
  };

  handleChecked = (info) => {
    // 1.체크 한 내용을 스테이트에 담는다.
    this.setState({
      selectedId: info.id,
      selectedLoginId: info.selectedAccount,
    });
  };

  handleConfirmIntegrate (selectedLoginId) {
    const response = window.confirm(`${selectedLoginId} (으)로 통합하시겠습니까?`);
    if (response) {
      this.postSelectedId();
    }
  }

  render() {
    const { classes } = this.props;
    const { data, isLoaded, selectedLoginId } = this.state;
    if (!isLoaded) return null;

    return (
      <React.Fragment>
        <DialogTitle>
          회원 계정 조회
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContent}>
            <h4>해당 전화번호로 가입된 계정은 다음과 같습니다.</h4>
            <h4>대표 계정을 선택해주세요.</h4>
          </div>
          <ul className={classes.list}>
            {isLoaded && data.map(info => (<ListContent key={info.id} info={info} handleChecked={this.handleChecked} />))}
          </ul>
          <button
            type="submit"
            className={classes.completeBtn}
            onClick={() => this.handleConfirmIntegrate(selectedLoginId)}
            onMouseEnter={this.onHover}
            onMouseLeave={this.onLeave}
          >
            선택 완료
          </button>
        </DialogContent>
      </React.Fragment >
    );
  }
}

export default withStyles(styles)(IntegrateModal);
