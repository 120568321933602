import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel, InputAdornment,
  Select,
  Switch,
} from '@material-ui/core';
import TextField from '@material-ui/core/es/TextField/TextField';
import { getDate } from '../common/utils';
import { PetSpecies } from '../common/constants';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    overflowX: 'hidden',
    padding: '0 2rem',
  },
  input: {
    margin: '12px',
    marginLeft: '0',
    width: '100%',
  },
  select: {
    margin: '12px',
    marginLeft: '0',
    width: '100%',
  },
  switch: {
    margin: '12px',
    marginLeft: '0',
  },
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  close: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  data: PropTypes.object,
};

const defaultProps = {
  data: {},
};

class PetFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      birthday: null,
      gender: '',
      type: '',
      species: '',
      weight: '',
      neutralization: false,
      isLoaded: false,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    let species = 'spe000';
    if (data.species) {
      for (let i = 0; i < PetSpecies.length; i++) {
        if (PetSpecies[i].name === data.species) {
          species = PetSpecies[i].key;
        }
      }
    }

    this.setState({
      data: data || false,
      name: data ? data.name : '',
      birthday: data ? data.birthday : getDate(),
      weight: data ? data.weight : '',
      gender: data ? data.gender : '',
      type: data ? data.type : '',
      species,
      neutralization: data ? data.neutralization : false,
      isLoaded: true,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  render() {
    const { classes, close, update } = this.props;
    const {
      name, birthday, weight, gender, type, species, neutralization, data, isLoaded,
    } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          <DialogTitle>
            {data.id ? '반려동물 정보 수정' : '새 반려동물 등록' }
          </DialogTitle>
          <DialogContent className={classes.content}>
            <TextField
              className={classes.input}
              label="반려동물 이름"
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
            />
            <br />
            <TextField
              className={classes.input}
              label="생년월일"
              type="date"
              value={birthday}
              onChange={e => this.setState({ birthday: e.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <TextField
              className={classes.input}
              label="몸무게"
              value={weight}
              InputProps={{
                endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
              }}
              onChange={e => this.setState({ weight: e.target.value })}
            />
            <br />
            <FormControl className={classes.select}>
              <InputLabel htmlFor="gender">성별</InputLabel>
              <Select native value={gender} onChange={e => this.setState({ gender: e.target.value })} inputProps={{ id: 'gender' }}>
                <option value="" />
                <option value="F">암컷</option>
                <option value="M">수컷</option>
              </Select>
            </FormControl>
            <br />
            <FormControl className={classes.select}>
              <InputLabel htmlFor="type">반려동물 종류</InputLabel>
              <Select
                native
                value={type}
                onChange={e => this.setState({ type: e.target.value })}
                inputProps={{ id: 'type' }}
                inputlabelprops={{
                  shrink: true,
                }}
              >
                <option value="none">선택하세요</option>
                <option value="D">강아지</option>
                <option value="C">고양이</option>
              </Select>
            </FormControl>
            <br />
            <FormControl
              className={classes.select}
            >
              <InputLabel htmlFor="species">반려동물 품종</InputLabel>
              <Select
                native
                value={species}
                onChange={e => this.setState({ species: e.target.value })}
                inputProps={{ id: 'species' }}
                inputlabelprops={{
                  shrink: true,
                }}
              >
                <option value="spe000">선택하세요</option>
                {PetSpecies.map(item => (
                  (type === 'D' && item.type === '강아지')
                  || (type === 'C' && item.type === '고양이')
                    ? <option value={item.key} key={item.id}>{item.name}</option>
                    : null))}
              </Select>
            </FormControl>
            <br />
            <FormControlLabel
              control={(
                <Switch
                  checked={neutralization}
                  onChange={e => this.setState({ neutralization: e.target.checked })}
                  color="primary"
                  className={classes.switch}
                />
            )}
              label="중성화 여부"
              labelPlacement="start"
              style={{ marginLeft: 0 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close()} color="primary">취소</Button>
            <Button
              onClick={() => update({
                name, birthday, weight, gender, type, species, neutralization, id: data.id,
              })}
              color="primary"
            >
            등록
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    } return <React.Fragment />;
  }
}

PetFormModal.propTypes = propTypes;
PetFormModal.defaultProps = defaultProps;

export default withStyles(styles)(PetFormModal);
