import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  FormControl,
  Icon, Input,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { signInStyle } from '../../common/styles';
import Fetch from '../../common/fetches';
import { withRouter, Link } from 'react-router-dom';


const styles = theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default,
    display: 'block', // Fix IE 11 issue.
    position: 'absolute',
    top: 0,
  },
  ...signInStyle,
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: '',
      id: '',
      pw: '',
      confirmPw: '',
    };
  }

  shouldComponentUpdate() {
    return false;
  }

  async register() {
    const {
      nickname, id, pw, confirmPw,
    } = this.state;
    if (!nickname || !id || !pw || !confirmPw) {
      alert('모두 입력해주세요.');
      return;
    }
    if (pw !== confirmPw) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }
    if (pw.length < 8) {
      alert('비밀번호는 8자리 이상으로 설정해주세요.');
      return;
    }
    const url = '/auth/register';
    const bodyData = {
      loginId: id,
      password: pw,
      nickname: nickname,
    };
    const res = await Fetch('post', url, bodyData);
    if (res.data) {
      alert('가입되었습니다.');
      this.props.history.push('/sign_in');
    } else {
      alert(res.error.msg);
    }
  }

  handlekeyPress(e) {
    if (e.keyCode === 13) {
      this.register();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.root}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Icon>lock</Icon>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="nickname">NickName</InputLabel>
              <Input
                id="nickname"
                name="nickname"
                autoFocus
                onChange={e => this.setState({ nickname: e.target.value })}
                onKeyDown={e => this.handlekeyPress(e)}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="id">ID</InputLabel>
              <Input
                id="id"
                name="id"
                onChange={e => this.setState({ id: e.target.value })}
                onKeyDown={e => this.handlekeyPress(e)}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="pw">Password</InputLabel>
              <Input
                name="pw"
                type="password"
                id="pw"
                onChange={e => this.setState({ pw: e.target.value })}
                onKeyDown={e => this.handlekeyPress(e)}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="confirmPw">Confirm Password</InputLabel>
              <Input
                name="confirmPw"
                type="password"
                id="confirmPw"
                onChange={e => this.setState({ confirmPw: e.target.value })}
                onKeyDown={e => this.handlekeyPress(e)}
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => this.register()}
            >
              Sign Up
            </Button>
          </form>
        </Paper>
        <Link to="/sign_in"><Button className={classes.signUp}>Sign In</Button></Link>
      </main>
    );
  }
}

SignUp.propTypes = propTypes;


export default withRouter(withStyles(styles)(SignUp));
