import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import PageTitle from '../../components/PageTitle';
import { pageStyle } from '../../common/styles';
import ListOfPets from './ListOfPets';

const styles = () => ({
  ...pageStyle,
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

class Pets extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.page}>
        <PageTitle>반려동물 관리</PageTitle>
        <ListOfPets />
      </main>
    );
  }
}

Pets.propTypes = propTypes;

export default withStyles(styles)(Pets);
