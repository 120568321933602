import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import classNames from 'classnames';
import Search from '../../components/Search';
import { getPhone } from '../../common/utils';
import { Spinner, tableStyle } from '../../common/styles';
import { MembersTableHead } from '../../common/constants';
import Fetch from '../../common/fetches';
import PetFormModal from '../../containers/PetFormModal';
import InspectionModal from '../../containers/InspectionModal';
import PetListModal from '../../containers/PetListModal';
import IntegrateModal from '../../containers/IntegrateModalOpen';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: "1rem",
    position: "relative",
  },
  select: {
    margin: "1rem",
    width: "90px",
  },
  searchInfo: {
    marginLeft: "1rem",
    color: "#aaa",
  },
  paper: {
    margin: "1rem",
    maxWidth: "100%",
  },
  idCell: {
    ...tableStyle.cell,
    overflowX: "auto",
    maxWidth: "180px",
  },
  iconCell: {
    ...tableStyle.cell,
    maxWidth: "12px",
    width: "12px",
  },
  ...tableStyle,
  ...Spinner,
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

class ListOfMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      inputType: "phone",
      searchInput: "",
      focusedData: {},
      isPetFormModalOpen: false,
      isPetListModalOpen: false,
      isInspectionModalOpen: false,
      isIntegrateModalOpen: false,
      isLoaded: false,
      csvData: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { inputType, searchInput } = this.props.location.state;
      this.setState({
        inputType: inputType,
        searchInput: searchInput,
      }, () => this.getAllMembers());
      return;
    }

    this.getAllMembers();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  /**
   * 페이지별 회원목록 조회
   * @param page
   * @returns {Promise<void>}
   */
  async getAllMembers(page = 0) {
    const { inputType, searchInput } = this.state;
    const url = `/members?page=${page + 1}&${inputType}=${searchInput}`;
    const res = await Fetch('get', url, {}, this.props.history);
    if (!res.members) {
      res.members = { data: [], total: 0, current_page: 1 };
    }
    if (!res.members.total && res.members.id) {
      res.members.data = [res.members];
      res.members.total = 1;
      res.members.current_page = 1;
    }
    this.setState({
      data: res,
      isLoaded: true,
    });
  }

  /**
   * 회원 수정(type=1), 탈퇴(type=2), 차단(type=3)
   * @param newData
   * @param type
   * @returns {Promise<void>}
   */
  async setMember(newData = {}, type) {
    const url = `/members/${newData.id}`;
    const bodyData = {
      type,
      ...newData,
    };
    const res = await Fetch("put", url, bodyData);
    if (res.msg) {
      alert(res.msg);
      this.setState({ isUpdateModalOpen: false }, () => this.getAllMembers());
    }
  }

  getLoginType(type) {
    switch (type) {
      case "A":
        return (
          <img src="/icons/email.png" alt="email" style={{ width: "16px" }} />
        );
      case "N":
        return (
          <img src="/icons/naver.PNG" alt="naver" style={{ width: "16px" }} />
        );
      case "F":
        return (
          <img
            src="/icons/facebook.png"
            alt="facebook"
            style={{ width: "16px" }}
          />
        );
      case "K":
        return (
          <img src="/icons/kakao.png" alt="kakao" style={{ width: "16px" }} />
        );
      case "I":
        return (
          <img src="/icons/apple.png" alt="kakao" style={{ width: "16px" }} />
        );
      default:
        return "";
    }
  }

  /**
   * 새로운 반려동물 등록
   * @param newData
   * @returns {Promise<void>}
   */
  async newPetRegister(newData) {
    const { focusedData } = this.state;
    if (
      !newData.name ||
      !newData.birthday ||
      !newData.gender ||
      !newData.type ||
      !newData.species ||
      !newData.weight
    ) {
      alert("모든 항목을 입력해주세요.");
      return;
    }
    const url = `/members/${focusedData.id}/pets`;
    const bodyData = {
      ...newData,
      neutralization: newData.neutralization ? "Y" : "N",
    };
    const res = await Fetch("post", url, bodyData);
    if (res.data) {
      alert("등록되었습니다.");
      this.setState({ isPetFormModalOpen: false });
    }
  }

  handleClick(id, type) {
    const res =
      type === 2
        ? window.confirm("탈퇴시키겠습니까?")
        : window.confirm("차단시키겠습니까?");
    if (res) {
      this.setMember({ id }, type);
    }
  }

  // 비밀번호 초기화 요청 함수
  async resetPassword(userId, newPassword) {
    try {
      const url = "/member_password";
      const body = {
        member_id: userId,
        password: newPassword,
      };
      const response = await Fetch("put", url, body);
      if (response && response.password === newPassword) {
        alert(
          `비밀번호 초기화에 성공했습니다. 변경된 비밀번호 : ${response.password}`
        );
        // this.getAllMembers();
      }
    } catch (error) {
      console.log(error);
      alert("에러가 발생했습니다. 다시 시도해주세요.");
    }
  }

  copyNumberToClipboard(randomPassword) {
    const tempInput = document.createElement("input");
    tempInput.setAttribute("value", randomPassword);
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  getRandomPassword(data) {
    const randomPassword = Math.floor(
      Math.random() * (10000000000 - 1000000000) + 1000000000
    );
    this.copyNumberToClipboard(randomPassword);

    const response = window.confirm(
      `${randomPassword} 로 비밀번호를 초기화하시겠습니까? (이 번호는 자동으로 클립보드에 복사됩니다. control + v로 붙여넣기하여 사용하세요.)`
    );
    if (response) {
      this.resetPassword(data.id, randomPassword.toString(10));
    }
  }

  // 초기화 가능 조건 확인 후 버튼 랜더
  getResetPasswordStatusText(classes, data) {
    if (
      data.geoLocation !== "KR" ||
      data.is_account_integrated !== "Y" ||
      data.status !== "Y"
    )
      return "초기화불가";
    return (
      <Button
        size="small"
        variant="outlined"
        color="primary"
        className={classes.tButton}
        onClick={() => this.getRandomPassword(data)}
      >
        초기화
      </Button>
    );
  }

  getIntegrateStatusText(classes, data) {
    if (data.geoLocation !== "KR" || data.status !== "Y") return "통합불가";
    switch (data.is_account_integrated) {
      case "Y":
        return "통합완료(Y)";
      case "N":
        return "통합완료(N)";
      default:
        return (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            className={classes.tButton}
            onClick={() =>
              this.setState({
                isIntegrateModalOpen: true,
                focusedData: data,
              })
            }
          >
            통합
          </Button>
        );
    }
  }

  async getCSVData() {
    const url = "/export?export_type=member";
    await Fetch("get", url).then((csvData) => {
      console.log("data", csvData);
      this.setState(
        {
          csvData: csvData.members,
          isLoading: false,
        },
        () => this.csvLink.current.link.click()
      );
    });
  }

  render() {
    const { classes } = this.props;
    const {
      data,
      inputType,
      searchInput,
      isInspectionModalOpen,
      isPetFormModalOpen,
      isPetListModalOpen,
      isIntegrateModalOpen,
      isLoaded,
      focusedData,
      isLoading,
    } = this.state;
    if (isLoaded) {
      return (
        <section className={classes.root}>
          <div style={{ position: "relative" }}>
            <FormControl className={classes.select}>
              <InputLabel htmlFor="inputType">검색조건</InputLabel>
              <Select
                value={inputType}
                onChange={(e) => this.setState({ inputType: e.target.value })}
              >
                <MenuItem value="phone">전화번호</MenuItem>
                <MenuItem value="email">이메일</MenuItem>
                <MenuItem value="member_id">등록번호</MenuItem>
              </Select>
            </FormControl>
            <Search
              searchBy={(value) =>
                this.setState({ searchInput: value }, () =>
                  this.getAllMembers()
                )
              }
            />
          </div>

          {isLoading && (
            <div className={classes.dimmer}>
              <CircularProgress className={classes.progress} />
            </div>
          )}

          {searchInput && (
            <Typography className={classes.searchInfo}>
              {`${searchInput} 검색결과`}
            </Typography>
          )}

          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headRow}>
                  {MembersTableHead.map((item) => (
                    <TableCell
                      key={item.key}
                      className={classNames(classes.cell, classes.headCell)}
                      padding="dense"
                    >
                      {item.value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.members.data.map((row) => (
                  <TableRow key={row.id} className={classes.bodyRow} hover>
                    <TableCell className={classes.cell}>{row.id}</TableCell>
                    <TableCell className={classes.idCell}>
                      {row.loginId}
                    </TableCell>
                    <TableCell className={classes.iconCell}>
                      {this.getLoginType(row.loginType)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.sub_path === "A" && "APP"}
                      {row.sub_path === "S" && "MALL"}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.app_version}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.phone && getPhone(row.phone)}
                    </TableCell>
                    <TableCell className={classes.iconCell}>
                      {row.deviceType === "A" && (
                        <img
                          src="/icons/android.png"
                          alt="android"
                          style={{ width: "24px" }}
                        />
                      )}
                      {row.deviceType === "I" && (
                        <img
                          src="/icons/apple.png"
                          alt="ios"
                          style={{ width: "24px" }}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.os_version}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.device_model}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.geoLocation}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.status === "Y" && (
                        <span style={{ color: "green" }}>활성</span>
                      )}
                      {row.status === "N" && (
                        <span style={{ color: "red" }}>탈퇴</span>
                      )}
                      {row.status === "B" && (
                        <span style={{ color: "brown" }}>차단</span>
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.created_at}
                    </TableCell>
                    <TableCell className={classes.cell} padding="none">
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        className={classes.tButton}
                        onClick={() => this.handleClick(row.id, 2)}
                      >
                        탈퇴
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        className={classes.tButton}
                        onClick={() => this.handleClick(row.id, 3)}
                      >
                        차단
                      </Button>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() =>
                          this.setState({
                            isPetFormModalOpen: true,
                            focusedData: row,
                          })
                        }
                      >
                        등록
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() =>
                          this.setState({
                            isPetListModalOpen: true,
                            focusedData: row,
                          })
                        }
                      >
                        조회
                      </Button>
                    </TableCell>

                    <TableCell className={classes.cell}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() =>
                          this.setState({
                            isInspectionModalOpen: true,
                            focusedData: row,
                          })
                        }
                      >
                        조회
                      </Button>
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {this.getIntegrateStatusText(classes, row)}
                    </TableCell>

                    <TableCell className={classes.cell}>
                      {this.getResetPasswordStatusText(classes, row)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow className={classes.row}>
                  <TablePagination
                    count={data.members.total}
                    rowsPerPageOptions={[15]}
                    onChangePage={(e, page) => this.getAllMembers(page)}
                    page={data.members.current_page - 1}
                    rowsPerPage={15}
                    className={classes.tPagination}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
          <Dialog 
            open={isPetFormModalOpen}
            onClose={() => this.setState({ isPetFormModalOpen: false })}
            maxWidth="xs"
            fullWidth
          >
            <PetFormModal
              close={() => this.setState({ isPetFormModalOpen: false })}
              update={(newData) => this.newPetRegister(newData)}
            />
          </Dialog>
          <Dialog
            open={isPetListModalOpen}
            onClose={() => this.setState({ isPetListModalOpen: false })}
            maxWidth={false}
          >
            <PetListModal id={focusedData.id} name={focusedData.name} />
          </Dialog>
          <Dialog
            open={isInspectionModalOpen}
            onClose={() => this.setState({ isInspectionModalOpen: false })}
            maxWidth={false}
          >
            <InspectionModal
              url={`/members/${focusedData.id}/inspections`}
              name={focusedData.name}
            />
          </Dialog>
          <Dialog
            open={isIntegrateModalOpen}
            onClose={() => this.setState({ isIntegrateModalOpen: false })}
            maxWidth={false}
          >
            <IntegrateModal
              phone={focusedData.phone}
              searchMember={() => this.getAllMembers()}
              closeModal={() => this.setState({ isIntegrateModalOpen: false })}
            />
          </Dialog>
        </section>
      );
    }
    return <section />;
  }
}

ListOfMembers.propTypes = propTypes;

export default withRouter(withStyles(styles)(ListOfMembers));
