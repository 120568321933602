import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import {
  Button,
  Paper, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { AdminsTableHead } from '../../common/constants';
import { tableStyle } from '../../common/styles';
import Fetch from '../../common/fetches';
import { getCookie } from '../../common/utils';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    margin: '1rem',
    overflowX: 'scroll',
    width: '700px',
  },
  ...tableStyle,
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {};

class ListOfAdmins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.getAllAdmins();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  async getAllAdmins(page = 0) {
    const url = `/auth/members?page=${page + 1}`;
    const res = await Fetch('get', url);
    this.setState({
      data: res,
      isLoaded: true,
    });
  }

  async setAdmin(id, type) {
    // 슈퍼관리자
    const loginData = getCookie('loginData') ? JSON.parse(getCookie('loginData')) : false;
    if (loginData.data.authority !== 'S') {
      alert('권한이 없습니다.');
      return;
    }
    const url = `/auth/member/${id}`;
    const bodyData = {
      type,
    };
    const res = await Fetch('put', url, bodyData);
    if (res.data.msg) {
      alert(res.data.msg);
      this.getAllAdmins();
    }
  }

  handleClick(id, type) {
    const res = type === 2
      ? window.confirm('삭제시키겠습니까?')
      : window.confirm('차단시키겠습니까?');
    if (res) {
      this.setAdmin(id, type);
    }
  }

  render() {
    const { classes } = this.props;
    const { data, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <section className={classes.root}>
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headRow}>
                  {AdminsTableHead.map(item => (
                    <TableCell key={item.key} className={classes.cell}>{item.value}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(row => (
                  <TableRow className={classes.bodyRow} hover key={row.id}>
                    <TableCell className={classes.cell}>{row.id}</TableCell>
                    <TableCell className={classes.cell}>{row.loginId}</TableCell>
                    <TableCell className={classes.cell}>{row.nickname}</TableCell>
                    <TableCell className={classes.cell}>
                      {row.status === 'B' && '차단'}
                      {row.status === 'N' && '탈퇴'}
                      {row.status === 'W' && '승인대기'}
                      {row.status === 'Y' && '활성'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() => this.setAdmin(row.id, 1)}
                      >
                        승인
                      </Button>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.tButton}
                        onClick={() => this.handleClick(row.id, 2)}
                      >
                        삭제
                      </Button>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.tButton}
                        onClick={() => this.handleClick(row.id, 3)}
                      >
                        차단
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </Paper>
        </section>
      );
    } return <section />;
  }
}

ListOfAdmins.propTypes = propTypes;
ListOfAdmins.defaultProps = defaultProps;

export default withStyles(styles)(ListOfAdmins);
