import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import {
  Icon, InputAdornment, TextField,
} from '@material-ui/core';

const styles = () => ({
  input: {
    margin: '1rem',
  },
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  searchBy: PropTypes.func.isRequired,
};

class Search extends Component {
  shouldComponentUpdate() {
    return false;
  }

  handleChange(e) {
    const { searchBy } = this.props;
    if (e.keyCode === 13) {
      searchBy(e.target.value);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <TextField
        className={classes.input}
        label="Search"
        placeholder="검색"
        onKeyDown={e => this.handleChange(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon>search</Icon>
            </InputAdornment>

          ),
        }}
      />
    );
  }
}

Search.propTypes = propTypes;

export default withStyles(styles)(Search);