import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routers from './Routers';
import { SERVER_URL } from './common/fetches';

class App extends Component {

  IS_DEV = process.env.REACT_APP_MODE === 'development';
  
  render() {
    console.log('v.0.1');
    return (
      <>
      {this.IS_DEV && 
      <div style={{background: 'white', height: '50px', paddingLeft: '205px', }}>
        <h1>개발모드?: {this.IS_DEV} </h1>
        <h1>Host: {SERVER_URL} </h1>
      </div>
      }
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
      </>
    );
  }
}

export default App;
