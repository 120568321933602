import React, { Component } from 'react';
import { getCookie } from '../../common/utils';
import { withRouter } from 'react-router-dom';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
    };
  }

  componentDidMount() {
    const loginData = getCookie('loginData') ? JSON.parse(getCookie('loginData')) : false;
    if (!loginData) {
      this.props.history.push('/sign_in');
    } else {
      this.props.history.push('/members');
    }
  }

  render() {
    return <div />;
  }
}

export default withRouter(Main);
