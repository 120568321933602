import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import PageTitle from '../../components/PageTitle';
import TabCont from '../../components/TabCont';
import { pageStyle } from '../../common/styles';
import ListOfMembers from './ListOfMembers';
import ListofAdmins from './ListOfAdmins';

const styles = () => ({
  ...pageStyle,
});

const menu = [
  { pid: 0, text: '일반회원' },
  { pid: 1, text: '관리자회원' },
];

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { tab } = this.state;
    if (nextState.tab === tab) return false;
    return true;
  }

  handleTabClick(value) {
    this.setState({ tab: value });
  }

  render() {
    const { classes } = this.props;
    const { tab } = this.state;
    return (
      <main className={classes.page}>
        <PageTitle>회원관리</PageTitle>
        <TabCont
          tabs={menu}
          tab={tab}
          handleTabClick={value => this.handleTabClick(value)}
        />
        { tab === 0 && (
          <ListOfMembers />
        )}
        { tab === 1 && (
          <ListofAdmins />
        )}
      </main>
    );
  }
}

Members.propTypes = propTypes;


export default withStyles(styles)(Members);
