import axios from 'axios/index';
import { getCookie } from './utils';

export const SERVER_URL = process.env.REACT_APP_MODE === 'development' ? 'https://admin-api-dev.fitpet.kr/api/v1/admin' : 'https://admin-api.fitpet.kr/api/v1/admin';

export default function Fetch(method, url, body = {}, history) {  
  const loginData = getCookie('loginData') ? JSON.parse(getCookie('loginData')) : false;
  const token = loginData ? loginData.token : 'NOT_HAVE_TOKEN';

  return new Promise((resolve, reject) => {
    axios(
      {
        method,
        url: `${SERVER_URL}${url}`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            alert('로그인이 필요합니다.');
            if (history) {
              history.push('/sign_in');
            } else {
              window.location.pathname = '/sign_in';
            }
          } else if (error.response.status === 400) {
            alert(error.response.data.error.msg);
          } else {
            alert('에러가 발생했습니다.');
          }
        }
      });
  });
}
