import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Button, Divider, Icon, List, ListItemSecondaryAction,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { MAIN_COLOR } from '../common/constants';
import Fetch from '../common/fetches';
import { getCookie } from '../common/utils';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #eee',
    width: '200px',
    height: '100vh',
    display: 'inline-block',
    boxSizing: 'border-box',
    cursor: 'default',
    position: 'fixed',
    left: 0,
    top: 0,
  },
  title: {
    color: MAIN_COLOR,
    fontSize: '1.3rem',
    fontWeight: '700',
  },
  logOut: {
    color: MAIN_COLOR,
    border: `1px solid ${MAIN_COLOR}`,
  },
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};


class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginData: {},
    };
  }

  componentDidMount() {
    this.getLoginData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  async logOut() {
    const res = await Fetch('get', '/auth/logout');
    if (res.msg) {
      alert('로그아웃되었습니다.');
      this.props.history.push('/sign_in');
    }
  }

  getLoginData() {
    const loginData = getCookie('loginData') ? JSON.parse(getCookie('loginData')) : false;
    this.setState({
      loginData,
    });
  }

  render() {
    const { classes } = this.props;
    const { loginData } = this.state;
    return (
      <List
        component="nav"
        className={classes.root}
        id="side-bar"
      >
        <Link to="/">
          <ListItem>
            <ListItemText className={classes.title} disableTypography>Fitpet Admin</ListItemText>
          </ListItem>
        </Link>
        <ListItem>
          <ListItemText>
            {`${loginData.data && loginData.data.nickname} 님`}
          </ListItemText>
          <ListItemSecondaryAction>
            <Button
              variant="outlined"
              className={classes.logOut}
              size="small"
              onClick={() => this.logOut()}
            >
              LogOut
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <Link to="/members">
          <ListItem button>
            <ListItemIcon>
              <Icon>people</Icon>
            </ListItemIcon>
            <ListItemText primary="회원관리" />
          </ListItem>
        </Link>
        <Link to="/pets">
          <ListItem button>
            <ListItemIcon>
              <Icon>pets</Icon>
            </ListItemIcon>
            <ListItemText primary="반려동물관리" />
          </ListItem>
        </Link>
        {/*<Link to="/boards">*/}
        {/*  <ListItem button>*/}
        {/*    <ListItemIcon>*/}
        {/*      <Icon>event_note</Icon>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="게시판관리" />*/}
        {/*  </ListItem>*/}
        {/*</Link>*/}
        {/*<Link to="/push">*/}
        {/*  <ListItem button>*/}
        {/*    <ListItemIcon>*/}
        {/*      <Icon>phonelink_setup</Icon>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="앱 푸시 관리" />*/}
        {/*  </ListItem>*/}
        {/*</Link>*/}
        {/*<Link to="/statistics">*/}
        {/*  <ListItem button>*/}
        {/*    <ListItemIcon>*/}
        {/*      <Icon>show_chart</Icon>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="통계" />*/}
        {/*  </ListItem>*/}
        {/*</Link>*/}
      </List>
    );
  }
}

SideBar.propTypes = propTypes;

export default withRouter(withStyles(styles)(SideBar));
