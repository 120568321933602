import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';

import SideBar from './components/SideBar';

import Main from './pages/main';
import Members from './pages/members';
import Pets from './pages/pets';

import SignIn from './pages/signIn';
import SignUp from './pages/signUp';

class Routers extends Component {
  componentDidMount() {
    const SideBarElement = document.getElementById('side-bar');
    if (this.props.history.location.pathname === '/sign_in') {
      SideBarElement.style.display = 'none';
    }
  }

  componentDidUpdate() {
    const SideBarElement = document.getElementById('side-bar');
    if (this.props.history.location.pathname === '/members') {
      SideBarElement.style.display = 'block';
    }
  }

  render() {
    return (
      <div>
        <SideBar />
        <Route exact path="/" component={Main} />
        <Route exact path="/members" component={Members} />
        <Route exact path="/pets" component={Pets} />
        
        <Route exact path="/sign_in" component={SignIn} />
        <Route exact path="/sign_up" component={SignUp} />
      </div>
    );
  }
}

export default withRouter(Routers);
