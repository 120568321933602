import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import {
  Button, CircularProgress, Dialog, FormControl, InputLabel, MenuItem,
  Paper, Select,
  Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import Search from '../../components/Search';
import {CsvBtn, Spinner, tableStyle} from '../../common/styles';
import { PetsTableHead } from '../../common/constants';
import Fetch from '../../common/fetches';
import MemberInfoModal from '../../containers/MeberInfoModal';
import PetFormModal from '../../containers/PetFormModal';
import InspectionModal from '../../containers/InspectionModal';
import PetInspectionFormModal from "../../containers/PetInspectionFormModal";
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: '1rem',
  },
  select: {
    margin: '1rem',
    width: '90px',
  },
  searchInfo: {
    marginLeft: '1rem',
    color: '#aaa',
  },
  paper: {
    margin: '1rem',
    overflowX: 'scroll',
  },
  ...tableStyle,
  ...CsvBtn,
  ...Spinner,
});

const propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

class ListOfPets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputType: 'name',
      searchInput: '',
      data: {},
      isLoaded: false,
      isMemberInfoOpen: false,
      focusedData: {},
      isPetFormModalOpen: false,
      isInspectionModalOpen: false,
      isPetInspectionFormModalOpen: false,
      csvData: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { inputType, searchInput } = this.props.location.state;
      this.setState({
        inputType: inputType,
        searchInput: searchInput,
      }, () => this.getAllPets());
      return;
    }

    this.getAllPets();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState === this.state) {
      return false;
    }
    return true;
  }

  /**
   * 페이지별 반려동물목록 조회
   * @param page
   * @returns {Promise<void>}
   */
  async getAllPets(page = 0) {
    const { inputType, searchInput } = this.state;
    const url = `/pets?page=${page + 1}&${inputType}=${searchInput}`;
    const res = await Fetch('get', url);
    if(!res.total && res[0]) {
      res.data = res;
      res.total = 1;
      res.current_page = 1;
    } else if(!res.total){
      res.data = [];
      res.total = 0;
      res.current_page = 1;
    }
    this.setState({
      data: res,
      isLoaded: true,
    });
  }

  /**
   * 반려동물 수정(type=1), 탈퇴(type=2), 차단(type=3)
   * @param newData
   * @param type
   * @returns {Promise<void>}
   */
  async setPet(newData = {}, type) {
    const url = `/pets/${newData.id}`;
    const bodyData = {
      modifyYype: type,
      ...newData,
    };
    const res = await Fetch('put', url, bodyData);

    if (res.msg) {
      alert(res.msg);
      this.setState({
        isPetFormModalOpen: false,
      }, () => this.getAllPets());
    }
  }

  async addInspection(newData, id) {
    if(!newData.type || !newData.result) {
      alert('검사지종류와 결과값을 모두 입력해주세요.');
      return;
    }

    const resultNumber = newData.result.split(' ').join('');
    const notIntRegex = /[^0-9]/gi;
    if (notIntRegex.test(resultNumber)) {
      alert('결과값은 숫자로만 입력해주세요.');
      return;
    }

    const url=`/pets/${id}/inspections`;
    const bodyData = {
      result: newData.result,
      aheadType: newData.type,
    }
    const res = await Fetch('post', url, bodyData);
    console.log('res', res);
    if(res.msg) {
      alert(res.msg);
      this.setState({
        isPetInspectionFormModalOpen : false,
      });
    }
  }

  async getCSVData() {
    const url = '/export?export_type=pet';
    await Fetch('get', url).then(csvData => {
      console.log('data', csvData);
      this.setState({
        csvData: csvData.members,
        isLoading: false,
      },() => this.csvLink.current.link.click())
    })
  }

  render() {
    const { classes, history } = this.props;
    const {
      searchInput, data, isLoaded, inputType,
      isMemberInfoOpen, focusedData, isPetFormModalOpen, isInspectionModalOpen, isPetInspectionFormModalOpen,
      isLoading,
    } = this.state;
    if (isLoaded) {
      return (
        <section className={classes.root}>
          <div style={{ position: 'relative' }}>
            <FormControl className={classes.select}>
              <InputLabel
                htmlFor="inputType"
              >
              검색조건
              </InputLabel>
              <Select
                value={inputType}
                onChange={e => this.setState({ inputType: e.target.value })}
              >
                <MenuItem value="name">이름</MenuItem>
                <MenuItem value="pet_id">등록번호</MenuItem>
              </Select>
            </FormControl>
            <Search
              searchBy={value => this.setState({ searchInput: value },
                () => this.getAllPets())}
            />
          </div>
          {isLoading &&
          <div className={classes.dimmer}>
            <CircularProgress className={classes.progress}/>
          </div>
          }
          {searchInput
        && (
        <Typography className={classes.searchInfo}>
          {`${searchInput} 검색결과`}
        </Typography>
        )
        }
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headRow}>
                  {PetsTableHead.map(item => (
                    <TableCell
                      key={item.key}
                      className={classNames(classes.cell, classes.headCell)}
                      padding="dense"
                    >
                      {item.value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { data.data.map(row => (
                  <TableRow key={row.id} className={classes.bodyRow} hover>
                    <TableCell className={classes.cell}>{row.id}</TableCell>
                    <TableCell className={classes.cell}>{row.name}</TableCell>
                    <TableCell className={classes.cell}>{row.member_name}</TableCell>
                    <TableCell className={classes.cell}>
                      {row.type === 'D'
                      && <img src="/icons/dog.png" alt="dog" className={classes.tIcon} />}
                      {row.type === 'C'
                      && <img src="/icons/cat.png" alt="cat" className={classes.tIcon} />}
                    </TableCell>
                    <TableCell className={classes.cell}>{row.species}</TableCell>
                    <TableCell className={classes.cell}>
                      {row.gender === 'F'
                      && <img src="/icons/female.png" alt="female" style={{ width: '16px' }} />}
                      {row.gender === 'M'
                      && <img src="/icons/male.png" alt="male" style={{ width: '16px' }} />}
                    </TableCell>
                    <TableCell className={classes.cell}>{`${row.weight} kg`}</TableCell>
                    <TableCell className={classes.cell}>{row.birthday}</TableCell>
                    <TableCell className={classes.cell}>
                      {row.neutralization === 'Y'
                      && <img src="/icons/checkmark.png" style={{ width: '16px' }} alt="checked" />}
                    </TableCell>
                    <TableCell className={classes.cell}>{row.created_at}</TableCell>
                    <TableCell className={classes.cell} padding="none">
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() => this.setState({
                          isPetFormModalOpen: true,
                          focusedData: row,
                        })}
                      >
                        수정
                      </Button>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() => this.setState({
                          isInspectionModalOpen: true,
                          focusedData: row,
                        })}
                      >
                        조회
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() => this.setState({
                          isPetInspectionFormModalOpen: true,
                          focusedData: row,
                        })}
                      >
                        등록
                      </Button>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() => this.setState({
                          isMemberInfoOpen: true,
                          focusedData: row,
                        })}
                      >
                        조회
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.tButton}
                        onClick={() => history.push("/members", { inputType: 'member_id', searchInput: row.member_id })}
                      >
                        이동
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow className={classes.row}>
                  <TablePagination
                    count={data.total}
                    rowsPerPageOptions={[15]}
                    onChangePage={(e, page) => this.getAllPets(page)}
                    page={data.current_page - 1}
                    rowsPerPage={15}
                    className={classes.tPagination}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
          <Dialog
            open={isPetFormModalOpen}
            onClose={() => this.setState({ isPetFormModalOpen: false })}
            maxWidth="xs"
            fullWidth
          >
            <PetFormModal
              close={() => this.setState({ isPetFormModalOpen: false })}
              update={newData => this.setPet(newData, 1)}
              data={focusedData}
            />
          </Dialog>

          <Dialog
            open={isInspectionModalOpen}
            onClose={() => this.setState({ isInspectionModalOpen: false })}
            maxWidth={false}
          >
            <InspectionModal url={`/pets/${focusedData.id}/inspections`} name={focusedData.name} />
          </Dialog>

          <Dialog
            open={isPetInspectionFormModalOpen}
            onClose={() => this.setState({ isPetInspectionFormModalOpen: false })}
            maxWidth="sm"
            fullWidth
          >
            <PetInspectionFormModal
              close={() => this.setState({ isPetInspectionFormModalOpen: false })}
              update={newData => this.addInspection(newData, focusedData.id)}
            />
          </Dialog>
          <Dialog
            open={isMemberInfoOpen}
            onClose={() => this.setState({ isMemberInfoOpen: false })}
            maxWidth={false}
          >
            <MemberInfoModal
              id={focusedData.id}
              onClose={() => this.setState({ isMemberInfoOpen: false })}
            />
          </Dialog>
        </section>
      );
    }
    return <section />;
  }
}

ListOfPets.propTypes = propTypes;

export default withRouter(withStyles(styles)(ListOfPets));
