import React from 'react';
import styled from 'styled-components';
import { MAIN_COLOR } from '../common/constants';

const PageTitle = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

export default PageTitle;

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 3%;
  background-color: ${MAIN_COLOR};
  color: #fff;
  font-size: 24px;
`;
